import React from 'react'
import { Link } from 'gatsby'

import Container from './container'
import * as styles from './find-us-banner.module.css'

const FindUsBanner = () => {

  return (
    <div className={styles.bannerContainer}>
      <Container className={styles.bannerContent}>
        <div>
          <h2>Nous trouver, c’est simple comme Tofutofu</h2>
          <Link to='/nous-trouver' className="button green">Trouver un point de vente</Link>
        </div>
      </Container>
    </div>
  )
}

export default FindUsBanner
