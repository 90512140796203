import React, { useState, useEffect } from 'react'

import * as styles from './instagram.module.css'

const Instagram = () => {

  const [insta, setInsta] = useState([]);
  useEffect(() => {
    if (!window.sessionStorage.getItem('instagram')) {
      fetch('/api/instagram')
      .then((data) => data.json())
      .then((data) => {
        let photosArray = data.data;
        photosArray = photosArray.filter(photo => photo.media_type !== 'VIDEO')
        setInsta(photosArray);
        window.sessionStorage.setItem("instagram", JSON.stringify(photosArray));
      });
    } else {
      setInsta(JSON.parse(window.sessionStorage.getItem('instagram')));
    }
  }, []);

  let width = 0;
  try {
      width = window.innerWidth;
  } catch(e) {}

  return (
    <div className={styles.instagram}>
      <div className={styles.container}>
        <h2>Suivez-nous sur les réseaux sociaux</h2>
        <p><a target="_blank" rel="noreferrer noopener" href='https://www.instagram.com/tofutofu.ca/'>@tofutofu.ca</a></p>
        <ul className={`${styles.postListFirstRow} `}>
          {insta.splice(0, 5).map((post) => {
            const description = post?.caption;
            return (
              <li key={post.id}>
                <a target="_blank" rel="noreferrer noopener" href={post.permalink} className={styles.link}>
                  {post.media_type === 'VIDEO' && <svg aria-label="Clip" color="#ffffff" fill="#ffffff" height="18" role="img" viewBox="0 0 24 24" width="18"><path d="m12.823 1 2.974 5.002h-5.58l-2.65-4.971c.206-.013.419-.022.642-.027L8.55 1Zm2.327 0h.298c3.06 0 4.468.754 5.64 1.887a6.007 6.007 0 0 1 1.596 2.82l.07.295h-4.629L15.15 1Zm-9.667.377L7.95 6.002H1.244a6.01 6.01 0 0 1 3.942-4.53Zm9.735 12.834-4.545-2.624a.909.909 0 0 0-1.356.668l-.008.12v5.248a.91.91 0 0 0 1.255.84l.109-.053 4.545-2.624a.909.909 0 0 0 .1-1.507l-.1-.068-4.545-2.624Zm-14.2-6.209h21.964l.015.36.003.189v6.899c0 3.061-.755 4.469-1.888 5.64-1.151 1.114-2.5 1.856-5.33 1.909l-.334.003H8.551c-3.06 0-4.467-.755-5.64-1.889-1.114-1.15-1.854-2.498-1.908-5.33L1 15.45V8.551l.003-.189Z" fill-rule="evenodd"></path></svg>}
                  {post.media_type === 'CAROUSEL_ALBUM' && <svg aria-label="Carousel" color="#ffffff" fill="#ffffff" height="22" role="img" viewBox="0 0 48 48" width="22"><path d="M34.8 29.7V11c0-2.9-2.3-5.2-5.2-5.2H11c-2.9 0-5.2 2.3-5.2 5.2v18.7c0 2.9 2.3 5.2 5.2 5.2h18.7c2.8-.1 5.1-2.4 5.1-5.2zM39.2 15v16.1c0 4.5-3.7 8.2-8.2 8.2H14.9c-.6 0-.9.7-.5 1.1 1 1.1 2.4 1.8 4.1 1.8h13.4c5.7 0 10.3-4.6 10.3-10.3V18.5c0-1.6-.7-3.1-1.8-4.1-.5-.4-1.2 0-1.2.6z"></path></svg>}
                  <img src={post.media_type === 'VIDEO' ? post.thumbnail_url :  post.media_url} />
                </a>
              </li>
            )
          })}
        </ul>
        {width > 1439 &&
        <ul className={styles.postList}>
          {insta.splice(-5).map((post) => {
            const description = post?.caption;
            return (
              <li key={post.id}>
                <a target="_blank" rel="noreferrer noopener" href={post.permalink} className={styles.link}>
                  {post.media_type === 'VIDEO' && <svg aria-label="Clip" color="#ffffff" fill="#ffffff" height="18" role="img" viewBox="0 0 24 24" width="18"><path d="m12.823 1 2.974 5.002h-5.58l-2.65-4.971c.206-.013.419-.022.642-.027L8.55 1Zm2.327 0h.298c3.06 0 4.468.754 5.64 1.887a6.007 6.007 0 0 1 1.596 2.82l.07.295h-4.629L15.15 1Zm-9.667.377L7.95 6.002H1.244a6.01 6.01 0 0 1 3.942-4.53Zm9.735 12.834-4.545-2.624a.909.909 0 0 0-1.356.668l-.008.12v5.248a.91.91 0 0 0 1.255.84l.109-.053 4.545-2.624a.909.909 0 0 0 .1-1.507l-.1-.068-4.545-2.624Zm-14.2-6.209h21.964l.015.36.003.189v6.899c0 3.061-.755 4.469-1.888 5.64-1.151 1.114-2.5 1.856-5.33 1.909l-.334.003H8.551c-3.06 0-4.467-.755-5.64-1.889-1.114-1.15-1.854-2.498-1.908-5.33L1 15.45V8.551l.003-.189Z" fill-rule="evenodd"></path></svg>}
                  {post.media_type === 'CAROUSEL_ALBUM' && <svg aria-label="Carousel" color="#ffffff" fill="#ffffff" height="22" role="img" viewBox="0 0 48 48" width="22"><path d="M34.8 29.7V11c0-2.9-2.3-5.2-5.2-5.2H11c-2.9 0-5.2 2.3-5.2 5.2v18.7c0 2.9 2.3 5.2 5.2 5.2h18.7c2.8-.1 5.1-2.4 5.1-5.2zM39.2 15v16.1c0 4.5-3.7 8.2-8.2 8.2H14.9c-.6 0-.9.7-.5 1.1 1 1.1 2.4 1.8 4.1 1.8h13.4c5.7 0 10.3-4.6 10.3-10.3V18.5c0-1.6-.7-3.1-1.8-4.1-.5-.4-1.2 0-1.2.6z"></path></svg>}
                  <img src={post.media_type === 'VIDEO' ? post.thumbnail_url :  post.media_url} />
                </a>
              </li>
            )
          })}
        </ul>}
      </div>
    </div>
  )
}

export default Instagram
