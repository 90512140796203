import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Container from './container'
import * as styles from './product-preview.module.css'

const ProductPreview = ({ products }) => {
  if (!products) return null
  if (!Array.isArray(products)) return null
  products = products.sort((a, b) => a.order - b.order)

  return (
    <Container className={styles.productListContainer}>
      <ul className={styles.productList}>
        {products.map((product) => {
          return (
            <li key={product.slug}>
              <Link to={`/produits/${product.slug}`} className={styles.link}>
                <GatsbyImage alt={product.title} image={product.heroImage.gatsbyImage} objectFit='cover' />
                <div className={styles.title}>{product.title}</div>
              </Link>
            </li>
          )
        })}
      </ul>
      <div className={styles.productLink}>
        <Link to='/produits' className="button black">Voir nos produits</Link>
      </div>
    </Container>
  )
}

export default ProductPreview
