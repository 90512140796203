import React from "react";
import Marquee from "react-gsap-marquee";
import * as styles from './marquee.module.css'

const MarqueeBanner = () => {

  return (
    <div>
      <div>
          <Marquee
            reverseOnScrollUp={false}
            maxVelocity={0}
          >
            <h6 className={styles.marquee}>Un tofu fumé fait de soya biologique cultivé localement. Tofutofu est fumé de façon artisanale, peu transformé et est un aliment délicieux, sain et prêt à manger.</h6>
          </Marquee>
      </div>
    </div>
  );
}

export default MarqueeBanner
