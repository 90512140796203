import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Container from './container'
import * as styles from './bio.module.css'

import Marquee from './marquee'

const Bio = () => {

  return (
    <div className={styles.bio}>
      <Container className={styles.bioContainer}>
        <div>
          <h2>L’artisane</h2>
          <h3>Derrière Tofutofu, il y a avant tout une femme passionnée</h3>
          <hr />
          <div className={styles.bioContent}>
            <StaticImage
              className={styles.portrait}
              alt="Dominique Dupuis"
              src="../../static/images/portrait.png"
              placeholder="blurred"
            />
            <div className={styles.bioContentText}>
              <p className={styles.bioIntro}>
                Habitée depuis longtemps par le désir d’aider les gens à accéder à une alimentation saine et végétale, Dominique, technologue en nutrition, carbure aux projets ambitieux.
              </p>
              <hr />
              <p className={styles.bioPara}>
                Fondatrice de l’école de cuisine végétale L’Armoire du Haut, elle y enseigne et développe des cours pendant 11 ans. Avec tout son savoir, son expertise et son amour pour une alimentation végétale, locale et saine, elle concrétise un second rêve en 2021, en fondant TOFUTOFU, une petite entreprise bien de chez nous. Sa mission? Faciliter l’accès, pour tous, à une protéine végétale simple et extrêmement goûteuse, qui permet de sauver un temps pré-cieux en cuisine. La première gamme de produits est maintenant prête : le tofu fumé! Un tofu prêt à manger, peu transformé et préparé avec des ingrédients que nos grands-mères utilisaient tous les jours. Voilà qui répond à tous les désirs de Dominique ! « Comme entrepreneure et maman, je comprends le défi quotidien qu’est celui de servir une alimentation saine et simple, que toute la famille aimera. Avec TOFU TOFU, c’est local, sain, peu transformé et surtout un prêt-à-manger… c’est ma motivation, mon ambition! À quelques pas de chez- moi, dans mon atelier, je prépare avec soin, pour vous un choix végé qui deviendra vite votre plus grand allié. »
              </p>
              <p className={styles.bioSignature}>
                Dominique Dupuis
              </p>
            </div>
          </div>
        </div>
      </Container>
      <div className={styles.marquee}>
        <Marquee />
      </div>
    </div>
  )
}

export default Bio
