export const sliderData = [
  {
    name: "Piquant",
    bgColor: "#FFE3E6"
  },
  {
    name: "Épices à steak",
    bgColor: "#EBD8BE"
  },
  {
    name: "",
    bgColor: "#FFE9CC"
  },
  {
    name: "Original",
    bgColor: "#DFE8E7"
  },
];
