// extracted by mini-css-extract-plugin
export var arrows = "hero-module--arrows--a20bc";
export var down = "hero-module--down--98b2d";
export var flavor = "hero-module--flavor--0965d";
export var flavor2 = "hero-module--flavor2--2d287";
export var flavor3 = "hero-module--flavor3--48621";
export var flavor4 = "hero-module--flavor4--6bb6e";
export var image = "hero-module--image--c04da";
export var image2 = "hero-module--image2--4bcd1";
export var image3 = "hero-module--image3--5b799";
export var image4 = "hero-module--image4--f47d3";
export var images = "hero-module--images--91b24";
export var logo = "hero-module--logo--284ec";
export var perso = "hero-module--perso--43bd0";
export var perso2 = "hero-module--perso2--6e471";
export var perso3 = "hero-module--perso3--be087";
export var perso4 = "hero-module--perso4--c4f14";
export var persos = "hero-module--persos--dd48d";
export var sliderContainer = "hero-module--sliderContainer--d7045";
export var text = "hero-module--text--26902";